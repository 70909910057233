import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import DOMPurify from "dompurify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useGetRule } from "../../hooks/useRules";
// import { checkRuleValue } from "../../services/helpers";
import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { useAlignment } from "../../context/alignmentFormbuilder/AlignmentContext";
import { checkRuleValue } from "../helpers/helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  className: "custom-menu-root",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const SingleSelectCheckbox = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    hide,
    disabled,
    labelAlignment,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
  const { Rules, triggerUpdate } = useGetRule();
  const [isDisabled, setDisabled] = React.useState(false);
  const [isVisible, setVisible] = React.useState(true);
  const { alignment } = useAlignment();

  const {
    textSize,
    width: customWidth,
    MaxValue,
    MinValue,
    subLabel,
    pageTitle,
  } = props?.data;

  useEffect(() => {
    const { form, formResponse } = Rules;
    if (formResponse) {
      const myId = props?.data.id;
      if (
        (form[myId] && form[myId]["dependsOn"]) ||
        (form[myId] && form[myId]["useCalculation"])
      ) {
        const ruleData = checkRuleValue(form[myId], formResponse);
        const { isVisible, isDisable } = ruleData;
        setVisible(isVisible);
        setDisabled(isDisable);
      }
    }
  }, [Rules]);
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const [personName, setPersonName] = React.useState("");

  const handleChange = (event) => {
    setPersonName(event.target.value);
    // props.set({ key: props.data.id, value: event.target.value });
    if (props?.set) {
      props?.set({ key: props.data.id, value: event.target.value });
    } else {
      console.warn("set function not provided in props");
    }
  };

  const sanitizedLabel = DOMPurify.sanitize(label || "");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${borderTop ? "borderTop " : ""} ${
            borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div
            className={`removeMargin flex ${
              hide
                ? "hideComponent"
                : alignment === "top"
                ? "flex-col "
                : alignment === "left"
                ? "justify-between items-center gap-20"
                : "justify-end items-center prevSpace gap-5 whitespace-nowrap"
            }`}
          >
            <Typography
              className={`custom-typography-class ${
                disabled ? "labelDisabled" : "labelContainer"
              }`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
            </Typography>
            <div
              className={`ageRangeInputBody checkboxClass flex gap-2 ${
                alignment === "top"
                  ? ""
                  : alignment === "left"
                  ? "order-last"
                  : ""
              }`}
            >
              <div>
                <div className="w-[462px]">
                  <Select
                    // sx={{ width: "100%" }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={personName}
                    className="max-w-xl"
                    style={{
                      width: customWidth ? `${customWidth}px` : "230px",
                    }}
                    onChange={handleChange}
                    input={<OutlinedInput placeholder="Single Check Box" />}
                    renderValue={(selected) => selected}
                    MenuProps={{
                      className: "custom-menu-root",
                    }}
                    ref={ref}
                    name={name}
                    defaultValue={defaultValue}
                    // disabled={isDisabled}
                    disabled={disabled || isDisabled}
                    placeholder="Single Check Box"
                    inputProps={{
                      "aria-label": "Without label",
                      className: "custom-select-class",
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: textSize
                          ? `${textSize}px !important`
                          : "12px !important",
                      },
                    }}
                  >
                    <MenuItem
                      disabled
                      value=""
                      sx={{
                        fontSize: textSize
                          ? `${textSize}px !important`
                          : "12px !important",
                      }}
                    >
                      <em>Single Select Checkbox</em>
                    </MenuItem>
                    {pageTitle?.split(",").map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={personName === name} />
                        <ListItemText
                          primary={name}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: textSize
                                ? `${textSize}px !important`
                                : "12px !important",
                            },
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {subLabel ? (
                  <span className="text-[10px] ml-1">{subLabel}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default SingleSelectCheckbox;
