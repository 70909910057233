import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DOMPurify from "dompurify";
import Modal from "@mui/material/Modal";

import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { useAlignment } from "../../context/alignmentFormbuilder/AlignmentContext";
import { checkRuleValue } from "../helpers/helper";
import { useGetRule } from "../../hooks/useRules";

const AgeRange = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    hide,
    disabled,
    border,
    borderTop,
    borderBottom,
    borderSides,
    labelAlignment,
    currentPage,
    label,
  } = props;

  const {
    textSize,
    width: customWidth,
    MaxValue,
    MinValue,
    subLabel,
  } = props.data;

  const [openModal, setOpenModal] = useState(false);
  const [titleName, setTitleName] = useState("");
  const [showTitle, setShowTitle] = useState(false);
  const [minDate, setMinDate] = useState(MinValue || "");
  const [maxDate, setMaxDate] = useState(MaxValue || "");
  const [isDisabled, setDisabled] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const { alignment } = useAlignment();
  const { Rules, triggerUpdate } = useGetRule();

  useEffect(() => {
    const { form, formResponse } = Rules;
    if (formResponse) {
      const myId = props?.data.id;
      const formItem = form[myId];
      if (
        (formItem && formItem["dependsOn"]) ||
        (formItem && formItem["useCalculation"])
      ) {
        const ruleData = checkRuleValue(formItem, formResponse);

        const { isVisible, isDisable } = ruleData;
        setVisible(isVisible);
        setDisabled(isDisable);
      }
    }
  }, [Rules]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const handleMinDateChange = (event) => {
    const selectedDate = event.target.value;
    setMinDate(selectedDate);
    if (maxDate && new Date(selectedDate) > new Date(maxDate)) {
      setMaxDate("");
    }
    if (props?.set) {
      props?.set({ key: props.data.id, value: selectedDate });
    } else {
      console.warn("set function not provided in props");
    }
    triggerUpdate(true);
  };

  const handleMaxDateChange = (event) => {
    setMaxDate(event.target.value);
    if (props?.set) {
      props?.set({ key: props.data.id, value: event.target.value });
    } else {
      console.warn("set function not provided in props");
    }
    triggerUpdate(true);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const sanitizedLabel = DOMPurify.sanitize(label || "");

  return (
    <>
      {isVisible && (
        <div
          className={`${border ? "borderFullRadius " : ""} ${
            borderTop ? "borderTop " : ""
          } ${borderSides ? "borderSides " : ""} ${
            borderBottom ? "borderBottom " : ""
          } flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div
            className={`removeMargin flex ${
              hide
                ? "hideComponent"
                : alignment === "top"
                ? "flex-col "
                : alignment === "left"
                ? "justify-between items-center gap-20"
                : "justify-end items-center gap-5 prevSpace"
            }`}
          >
            <Typography
              className={`custom-typography-class ${
                disabled ? "labelDisabled" : "labelContainer"
              }`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              {label === "" ? (
                "Age Range"
              ) : (
                <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
              )}
            </Typography>
            <div
              className={`datePadding ageRangeInputBody ${
                alignment === "top"
                  ? ""
                  : alignment === "left"
                  ? "order-last"
                  : ""
              }`}
              id="dateRangeBody"
            >
              <div>
                <div className="w-[462px]">
                  <div className="mb-2 ">
                    <TextField
                      className={`muiDatetInput max-w-xl`}
                      id="outlined-basic-from"
                      type="date"
                      ref={ref}
                      style={{
                        width: customWidth ? `${customWidth}px` : "230px",
                        fontSize: textSize ? `${textSize}px` : "1.25rem",
                      }}
                      name={`${name}_from`}
                      placeholder="From"
                      variant="outlined"
                      defaultValue={defaultValue}
                      disabled={isDisabled || disabled}
                      value={minDate}
                      onChange={handleMinDateChange}
                      inputProps={{
                        min: MinValue,
                        max: MaxValue,
                        className: "custom-date-class",
                      }}
                      InputProps={{
                        style: {
                          fontSize: textSize ? `${textSize}px` : "12px",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      className={`muiDatetInput max-w-xl`}
                      id="outlined-basic-to"
                      type="date"
                      ref={ref}
                      name={`${name}_to`}
                      style={{
                        width: customWidth ? `${customWidth}px` : "230px",
                      }}
                      placeholder="To"
                      variant="outlined"
                      defaultValue={defaultValue}
                      disabled={isDisabled || disabled}
                      value={maxDate}
                      onChange={handleMaxDateChange}
                      inputProps={{
                        min: minDate,
                        max: maxDate,
                        className: "custom-date-class",
                      }}
                      InputProps={{
                        style: {
                          fontSize: textSize ? `${textSize}px` : "12px",
                        },
                      }}
                    />
                  </div>
                </div>
                {subLabel ? (
                  <span className="text-[10px] ml-1 mt-3">{subLabel}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default AgeRange;
