import { useEffect, useState } from "react";
import { getAllManageChoiceDataSevice } from "../Questionnaire";
import DOMPurify from "dompurify";
const ManageChoice = ({ state }) => {
  const { fieldId, questionDataId } = state || {};

  const [ChoiceData, setChoiceData] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    fetchManageChoice(fieldId);
  }, []);

  const choiceDatarows = ChoiceData.map((row) => ({
    id: row.id,
    optionTranslationKey: row.optionTranslationKey,
    choiceDisplayText: DOMPurify.sanitize(row.choiceDisplayText),
    value: row.value,
    sequence: row.sequence,
    delete: row.id,
    optionGroupKey: row.optionGroupKey,
    clearOtherResponses: row.clearOtherResponses,
    leftPosition: row.leftPosition,
    width: row.width,
    questionId: row.questionId,
    studyFileId: row.studyFileId,
    textAlignment: row.textAlignment,
    allowExpand: row.allowExpand,
  }));

  const fetchManageChoice = async (fieldId) => {
    try {
      setLoad(true);
      const res = await getAllManageChoiceDataSevice(fieldId);
      if (res.status) {
        setLoad(false);
        setChoiceData(res.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  return (
    <div>
      <h5 className="font-bold">Manage Choices</h5>
      <table className="w-full border border-collapse border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">
              OptionTranslationKey
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">
              ChoiceDisplayText
            </th>
          </tr>
        </thead>
        <tbody>
          {choiceDatarows.map((row, index) => (
            <tr
              key={row.id}
              className={index % 2 === 1 ? "bg-white" : "bg-white"}
            >
              <td className="border border-gray-300 px-4 py-2">
                {row.optionTranslationKey}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <span dangerouslySetInnerHTML={{ __html: row.choiceDisplayText }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageChoice;
