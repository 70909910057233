import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "@mui/material/Modal";

import { useGetRule } from "../../hooks/useRules";
// import { checkRuleValue } from "../../services/helpers";
import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { checkRuleValue } from "../helpers/helper";

const Label = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    disabled,
    hide,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
    const { Rules, triggerUpdate } = useGetRule();
  const [isDisabled, setDisabled] = React.useState(disabled);
  const [isVisible, setVisible] = React.useState(true);

    useEffect(() => {
      const { form, formResponse } = Rules;
      if (formResponse) {
        const myId = props?.data.id;
        if (
          (form[myId] && form[myId]["dependsOn"]) ||
          (form[myId] && form[myId]["useCalculation"])
        ) {
          const ruleData = checkRuleValue(form[myId], formResponse);
          const { isVisible, isDisable } = ruleData;
          setVisible(isVisible);
          setDisabled(isDisable);
        }
      }
    }, [Rules]);
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    props.set({ key: props.data.id, value: event.target.value });
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${borderTop ? "borderTop " : ""} ${
            borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* // <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div className={`${hide ? "hideComponent" : "labelContainer"}`}>
            <div className={`${disabled ? "labelDisabled" : "labelContainer"}`}>
              <div dangerouslySetInnerHTML={{ __html: label }} />
            </div>
            <Typography
              className={`custom-typography-class ${disabled ? "labelDisabled" : "labelContainer"}`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              <p>
                <div dangerouslySetInnerHTML={{ __html: label }} />
              </p>
            </Typography>
          </div>
        </div>
      )}
    </>
  );
});

export default Label;
