import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Oval } from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import DOMPurify from "dompurify";
import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { useAlignment } from "../../context/alignmentFormbuilder/AlignmentContext";

const NumberSpinner = React.forwardRef((props, ref) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);
  const { alignment } = useAlignment();

  const {
    textSize,
    width: customWidth,
    MaxValue,
    MinValue,
    subLabel,
  } = props.data;
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    labelAlignment,
    hide,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;

  const sanitizedLabel = DOMPurify.sanitize(label || "");
  return (
    <>
      <div
        className={`${borderTop ? "borderTop " : ""} ${
          borderSides ? "borderSides " : ""
        } ${borderBottom ? "borderBottom " : ""} flexCenter`}
      >
        {currentPage && (
          <div className="currentPage">
            {/* Page <p>{currentPage}</p> */}
            {!showTitle ? (
              <>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "2%",
                          }}
                        >
                          <h4>Enter Page Title</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            className="muiTextInput"
                            id="outlined-basic"
                            onChange={handleTitleName}
                            placeholder="Page Title"
                            variant="outlined"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "4%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={handleShowTitle}
                            className="saveTitleBtn"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Modal>
                {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                {/* <div className="addTitleBtn">
                  Container Title
                </div> */}
              </>
            ) : (
              <p>{titleName}</p>
            )}
          </div>
        )}
        <div
          className={`removeMargin flex ${
            hide
              ? "hideComponent"
              : alignment === "top"
              ? "flex-col "
              : alignment === "left"
              ? "justify-between items-center gap-20"
              : "justify-end items-center prevSpace gap-5"
          }`}
        >
          <Typography
            className={`custom-typography-class ${disabled ? "labelDisabled" : "labelContainer"}`}
            sx={{ mt: 4, mb: 2 }}
            style={{
              fontSize: textSize ? `${textSize}px` : "1.25rem",
            }}
            variant="h6"
            component="div"
          >
            <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
          </Typography>
          <div
            className={`ageRangeInputBody flex gap-2 ${
              alignment === "top"
                ? ""
                : alignment === "left"
                ? "order-last"
                : ""
            }`}
          >
            <div>
              <div className="w-[462px]">
                <Oval
                  name={name}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  ref={ref}
                  height={80}
                  width={customWidth ? `${customWidth}` : "80"}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
              {subLabel ? (
                  <span className="text-[10px] ml-1">{subLabel}</span>
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default NumberSpinner;
