import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DOMPurify from "dompurify";
import { useGetRule } from "../../hooks/useRules";
import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { useAlignment } from "../../context/alignmentFormbuilder/AlignmentContext";
import { checkRuleValue } from "../helpers/helper";

const DobAge = React.forwardRef((props, ref) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);
  const { Rules, triggerUpdate } = useGetRule();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    hide,
    labelAlignment,
    border,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
  const [isDisabled, setDisabled] = React.useState(false);
  const [isVisible, setVisible] = React.useState(true);
  const { alignment } = useAlignment();

  const {
    textSize,
    width: customWidth,
    MaxValue,
    MinValue,
    subLabel,
  } = props.data;

  useEffect(() => {
    const { form, formResponse } = Rules;
    if (formResponse) {
      const myId = props?.data.id;
      if (
        (form[myId] && form[myId]["dependsOn"]) ||
        (form[myId] && form[myId]["useCalculation"])
      ) {
        const ruleData = checkRuleValue(form[myId], formResponse);
        const { isVisible, isDisable } = ruleData;
        console.log(ruleData, "89");
        setVisible(isVisible);
        setDisabled(isDisable);
      }
    }
  }, [Rules]);
  const [dobArray, setDobArray] = React.useState([
    {
      name: "one",
      value: 1,
    },
    {
      name: "two",
      value: 2,
    },
    {
      name: "three",
      value: 3,
    },
    {
      name: "four",
      value: 4,
    },
    {
      name: "five",
      value: 5,
    },
    {
      name: "six",
      value: 6,
    },
    {
      name: "seven",
      value: 7,
    },
    {
      name: "eight",
      value: 8,
    },
    {
      name: "nine",
      value: 9,
    },
    {
      name: "ten",
      value: 10,
    },
    {
      name: "eleven",
      value: 11,
    },
    {
      name: "twelve",
      value: 12,
    },
    {
      name: "thirteen",
      value: 13,
    },
    {
      name: "fourteen",
      value: 14,
    },
    {
      name: "fifteen",
      value: 15,
    },
    {
      name: "sixteen",
      value: 16,
    },
    {
      name: "seventeen",
      value: 17,
    },
    {
      name: "eighteen",
      value: 18,
    },
    {
      name: "nineteen",
      value: 19,
    },
    {
      name: "twenty",
      value: 20,
    },

    {
      name: "twenty-one",
      value: 21,
    },
    {
      name: "twenty-two",
      value: 22,
    },
    {
      name: "twenty-three",
      value: 23,
    },
    {
      name: "twenty-four",
      value: 24,
    },
    {
      name: "twenty-five",
      value: 25,
    },
    {
      name: "twenty-six",
      value: 26,
    },
    {
      name: "twenty-seven",
      value: 27,
    },
    {
      name: "twenty-eight",
      value: 28,
    },
    {
      name: "twenty-nine",
      value: 29,
    },
    {
      name: "thirty",
      value: 30,
    },

    {
      name: "thirty-one",
      value: 31,
    },
    {
      name: "thirty-two",
      value: 32,
    },
    {
      name: "thirty-three",
      value: 33,
    },
    {
      name: "thirty-four",
      value: 34,
    },
    {
      name: "thirty-five",
      value: 35,
    },
    {
      name: "thirty-six",
      value: 36,
    },
    {
      name: "thirty-seven",
      value: 37,
    },
    {
      name: "thirty-eight",
      value: 38,
    },
    {
      name: "thirty-nine",
      value: 39,
    },
    {
      name: "fourty",
      value: 40,
    },

    {
      name: "fourty-one",
      value: 41,
    },
    {
      name: "fourty-two",
      value: 42,
    },
    {
      name: "fourty-three",
      value: 43,
    },
    {
      name: "fourty-four",
      value: 44,
    },
    {
      name: "fourty-five",
      value: 45,
    },
    {
      name: "fourty-six",
      value: 46,
    },
    {
      name: "fourty-seven",
      value: 47,
    },
    {
      name: "fourty-eight",
      value: 48,
    },
    {
      name: "fourty-nine",
      value: 49,
    },
    {
      name: "fifty",
      value: 50,
    },

    {
      name: "fifty-one",
      value: 51,
    },
    {
      name: "fifty-two",
      value: 52,
    },
    {
      name: "fifty-three",
      value: 53,
    },
    {
      name: "fifty-four",
      value: 54,
    },
    {
      name: "fifty-five",
      value: 55,
    },
    {
      name: "fifty-six",
      value: 56,
    },
    {
      name: "fifty-seven",
      value: 57,
    },
    {
      name: "fifty-eight",
      value: 58,
    },
    {
      name: "fifty-nine",
      value: 59,
    },
    {
      name: "sixty",
      value: 60,
    },

    {
      name: "sixty-one",
      value: 61,
    },
    {
      name: "sixty-two",
      value: 62,
    },
    {
      name: "sixty-three",
      value: 63,
    },
    {
      name: "sixty-four",
      value: 4,
    },
    {
      name: "sixty-five",
      value: 65,
    },
    {
      name: "sixty-six",
      value: 66,
    },
    {
      name: "sixty-seven",
      value: 67,
    },
    {
      name: "sixty-eight",
      value: 68,
    },
    {
      name: "sixty-nine",
      value: 69,
    },
    {
      name: "seventy",
      value: 70,
    },

    {
      name: "seventy-one",
      value: 71,
    },
    {
      name: "seventy-two",
      value: 72,
    },
    {
      name: "seventy-three",
      value: 73,
    },
    {
      name: "seventy-four",
      value: 74,
    },
    {
      name: "seventy-five",
      value: 75,
    },
    {
      name: "seventy-six",
      value: 76,
    },
    {
      name: "seventy-seven",
      value: 77,
    },
    {
      name: "seventy-eight",
      value: 78,
    },
    {
      name: "seventy-nine",
      value: 79,
    },
    {
      name: "eighty",
      value: 80,
    },

    {
      name: "eighty-one",
      value: 81,
    },
    {
      name: "eighty-two",
      value: 82,
    },
    {
      name: "eighty-three",
      value: 83,
    },
    {
      name: "eighty-four",
      value: 84,
    },
    {
      name: "eighty-five",
      value: 85,
    },
    {
      name: "eighty-six",
      value: 86,
    },
    {
      name: "eighty-seven",
      value: 87,
    },
    {
      name: "eighty-eight",
      value: 88,
    },
    {
      name: "eighty-nine",
      value: 89,
    },
    {
      name: "ninety",
      value: 90,
    },

    {
      name: "ninety-one",
      value: 91,
    },
    {
      name: "ninety-two",
      value: 92,
    },
    {
      name: "ninety-three",
      value: 93,
    },
    {
      name: "ninety-four",
      value: 94,
    },
    {
      name: "ninety-five",
      value: 95,
    },
    {
      name: "ninety-six",
      value: 96,
    },
    {
      name: "ninety-seven",
      value: 97,
    },
    {
      name: "ninety-eight",
      value: 98,
    },
    {
      name: "ninety-nine",
      value: 99,
    },
    {
      name: "ninety",
      value: 100,
    },
  ]);

  const [dobAge, setDobAge] = React.useState("");

  const handleChange = (event) => {
    event.preventDefault();
    const inputValue = parseInt(event.target.value, 10);
  
    // Check if MinValue and MaxValue are provided
    if (MinValue && MaxValue) {
      if (inputValue >= MinValue && inputValue <= MaxValue) {
        setDobAge(inputValue);
        if (props?.set) {
          props?.set({ key: props.data.id, value: inputValue });
        }
      } else {
        setDobAge("");
        if (props?.set) {
          props?.set({ key: props.data.id, value: "" });
        }
        console.warn(`Value must be between ${MinValue} and ${MaxValue}`);
      }
    } else {
      // If MinValue and MaxValue are not provided, accept any value
      setDobAge(inputValue);
      if (props?.set) {
        props?.set({ key: props.data.id, value: inputValue });
      }
    }
  
    triggerUpdate(true);
  };

  const sanitizedLabel = DOMPurify.sanitize(label || "");

  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${border ? "borderFullRadius " : ""} ${
            borderTop ? "borderTop " : ""
          } ${borderSides ? "borderSides " : ""} ${
            borderBottom ? "borderBottom " : ""
          } flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div
            className={`removeMargin flex ${
              hide
                ? "hideComponent"
                : alignment === "top"
                ? "flex-col "
                : alignment === "left"
                ? "justify-between items-center gap-20"
                : "justify-end items-center prevSpace gap-5"
            }`}
          >
            <Typography
              className={`custom-typography-class ${disabled ? "labelDisabled" : "labelContainer"}`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
            </Typography>
            <div
              className={`ageRangeInputBody flex gap-2 ${
                alignment === "top"
                  ? ""
                  : alignment === "left"
                  ? "order-last"
                  : ""
              }`}
            >
              <div>
                <div className="w-[462px]">
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="max-w-xl"
                    value={dobAge}
                    style={{
                      width: customWidth ? `${customWidth}px` : "230px",
                    }}
                    name={name}
                    ref={ref}
                    defaultValue={defaultValue}
                    // disabled={isDisabled}
                    disabled={disabled || isDisabled}
                    onChange={handleChange}
                    placeholder="DOB Age"
                    inputProps={{
                      "aria-label": "Without label",
                      className: "custom-input-class",
                    }}
                    MenuProps={{
                      className: "custom-menu-root",
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: textSize ? `${textSize}px !important` : "12px !important"
                      },
                    }}
                  >
                    <MenuItem disabled value="" sx={{fontSize: textSize ? `${textSize}px !important` : "12px !important"}}>
                      <em>DOB Age</em>
                    </MenuItem>
                    {dobArray.map((item, index) => (
                      <MenuItem key={index} value={item.value} sx={{fontSize: textSize ? `${textSize}px !important` : "12px !important"}}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {subLabel ? (
                <span className="text-[10px] ml-1">{subLabel}</span>
              ) : (
                ""
              )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default DobAge;
