import { getCalculationById } from "../../services/calculation";
import axios from "axios";
import { reject } from "lodash";

export const checkRuleValue = (formElement, formResponse) => {
  const {
    dependsOn,
    hasValue,
    doRule,
    hasCondition,
    useCalculation,
    calculation,
  } = formElement;

  // console.log("useCalculation", formElement , useCalculation, calculation);
  if (useCalculation) {
    // console.log("rule from calculations");
    const calculationValue = evaluateCalculation(calculation, hasValue);
    // console.log("calculationValue", calculationValue);
    if (calculationValue) {
      return applyDoRule(doRule);
    } else {
      return {
        isVisible: true,
        isDisable: false,
      };
    }
  } else {
    if (hasValue && hasValue != "" && dependsOn != undefined) {
      let ruleApplies = parseLogicalExpression(
        hasValue,
        formResponse[dependsOn],
        hasCondition
      );
      // console.log("ruleApplies", ruleApplies, hasValue, hasCondition, formResponse[dependsOn],);
      if (ruleApplies) {
        return applyDoRule(doRule);
      } else {
        return {
          isVisible: true,
          isDisable: false,
        };
      }
    } else {
      return {
        isVisible: true,
        isDisable: false,
      };
    }
  }
};
const parseLogicalExpression = (expectedValue, actualValue, expression) => {
  let result = false;
  // console.log("54", expectedValue);
  // console.log("55", actualValue.props);
  // console.log("56", expression);

  let checkType = typeof actualValue;
  if (checkType == "Number") {
    expectedValue = expectedValue ? parseInt(expectedValue) : expectedValue;
    actualValue = actualValue ? parseInt(actualValue) : actualValue;
  }
  if (checkType == "String") {
    expectedValue = expectedValue
      ? expectedValue.toLowerCase().replace(/\s/g, "")
      : expectedValue;
    actualValue = actualValue
      ? actualValue.toLowerCase().replace(/\s/g, "")
      : actualValue;
  }
  if (actualValue != "") {
    switch (expression) {
      case "==":
        // console.log("selected expression is ==");
        if (actualValue == expectedValue) {
          result = true;
        }
        break;
      case "<":
        // console.log("selected expression is <");
        if (actualValue < expectedValue) {
          result = true;
        }
        break;
      case ">":
        // console.log("selected expression is >");
        if (actualValue > expectedValue) {
          result = true;
        }
        break;
      case "<=":
        // console.log("selected expression is <=");
        if (actualValue <= expectedValue) {
          result = true;
        }
        break;
      case ">=":
        // console.log("selected expression is >=");
        if (actualValue >= expectedValue) {
          result = true;
        }
        break;
      default:
        result = false;
    }
  }
  return result;
};
const evaluateCalculation = async (calculation, hasValue) => {
  const calculationItem = await getCalculationById(calculation);

  const { data } = calculationItem;
  const { url, successResponse, endpointType } = data;
  const apiResponse = await callEndpoint(endpointType.name.toLowerCase(), url);
  const actualValue = fetchKeyFromApiResponse(
    apiResponse,
    successResponse?.replaceAll('"', "")
  );

  return actualValue.toLowerCase() == hasValue.toLowerCase();
};

const fetchKeyFromApiResponse = (apiResponse, successResponse) => {
  const keyList = successResponse.split(">");
  let responseValue = apiResponse;
  keyList.map((key) => {
    responseValue = responseValue[key];
  });
  return responseValue;
};
const applyDoRule = (doRule) => {
  // console.log("doRule", doRule);
  let rules = {};
  if (doRule == "true") {
    rules = {
      isVisible: true,
      isDisable: false,
    };
  } else if (doRule == "false") {
    rules = {
      isVisible: false,
      isDisable: false,
    };
  } else if (doRule == "-1") {
    rules = {
      isVisible: true,
      isDisable: true,
    };
  }
  // console.log("rules", rules);
  return rules;
};
const callEndpoint = async (type, endpoint) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: type,
      maxBodyLength: Infinity,
      url: endpoint,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const fetcCalculationResults = (calculationData) => {};
