import { studyServer } from "./study_axios"

export const getAllEmailContent = (pageNumber, pageSize) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/EmailBuilder/GetEmailTemplatesPagination?pageNumber=${pageNumber ? pageNumber : 1}&pageSize=${pageSize ? pageSize : 10}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getEmailContentById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/EmailBuilder/EditOnloadData?emailContentId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createEmailContent = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/EmailBuilder/CreateTemplete`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editEmailContent = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/EmailBuilder/UpdateTemplete`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteEmailContent = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .delete(`/EmailContent/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const getAllEmailTypeData = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/EmailBuilder/GetEmailContentTypes`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const getGetPatientStatusTypesData = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Visit/GetPatientStatusTypes`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
