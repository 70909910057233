import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DOMPurify from "dompurify";

import { useGetRule } from "../../hooks/useRules";
import "../../pages/admin/StudySetup/Questionnaire/FormBuilder/QuestionnaireCss.css";
import { useAlignment } from "../../context/alignmentFormbuilder/AlignmentContext";
import { checkRuleValue } from "../helpers/helper";

const DropDown = React.forwardRef((props, ref) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);
  const { Rules, triggerUpdate } = useGetRule();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const {
    textSize,
    width: customWidth,
    MaxValue,
    MinValue,
    subLabel,
    pageTitle
  } = props?.data;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    labelAlignment,
    hide,
    border,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
  const [isDisabled, setDisabled] = React.useState(false);
  const [isVisible, setVisible] = React.useState(true);
  const { alignment } = useAlignment();
  useEffect(() => {
    const { form, formResponse } = Rules;
    if (formResponse) {
      const myId = props?.data.id;
      if (
        (form[myId] && form[myId]["dependsOn"]) ||
        (form[myId] && form[myId]["useCalculation"])
      ) {
        const ruleData = checkRuleValue(form[myId], formResponse);
        const { isVisible, isDisable } = ruleData;
        setVisible(isVisible);
        setDisabled(isDisable);
      }
    }
  }, [Rules]);
  // const [dropDown, setDropDown] = React.useState(
  //   props?.data?.pageTitle?.split(",") || []
  // );

  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event) => {
    // props.set({ key: props.data.id, value: event.target.value });
    setSelectedOption(event.target.value);
  };

  const sanitizedLabel = DOMPurify.sanitize(label || "");
  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${border ? "borderFullRadius " : ""} ${
            borderTop ? "borderTop " : ""
          } ${borderSides ? "borderSides " : ""} ${
            borderBottom ? "borderBottom " : ""
          } flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div
            className={`removeMargin flex ${
              hide
                ? "hideComponent"
                : alignment === "top"
                ? "flex-col "
                : alignment === "left"
                ? "justify-between items-center gap-20"
                : "justify-end items-center prevSpace gap-5"
            }`}
          >
            <Typography
              className={`custom-typography-class ${
                disabled ? "labelDisabled" : "labelContainer"
              }`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              <p>
                {label === "" ? (
                  "Drop Down"
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
                )}
              </p>
            </Typography>

            <div
              className={`ageRangeInputBody flex gap-2 ${
                alignment === "top"
                  ? ""
                  : alignment === "left"
                  ? "order-last"
                  : ""
              }`}
            >
              <div>
                <div className="w-[462px]">
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    style={{
                      width: customWidth ? `${customWidth}px` : "230px",
                    }}
                    className="max-w-xl"
                    value={selectedOption}
                    name={name}
                    ref={ref}
                    defaultValue={defaultValue}
                    // disabled={isDisabled}
                    disabled={disabled || isDisabled}
                    onChange={handleChange}
                    placeholder="Drop Down"
                    inputProps={{
                      "aria-label": "Without label",
                      className: "custom-input-class",
                    }}
                    MenuProps={{
                      className: "custom-menu-root",
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: textSize ? `${textSize}px !important` : "12px !important"
                      },
                    }}
                  >
                    <MenuItem
                      disabled
                      value=""
                      sx={{
                        fontSize: textSize
                          ? `${textSize}px !important`
                          : "12px !important",
                      }}
                    >
                      <em>Drop Down</em>
                    </MenuItem>
                    {pageTitle?.split(",").map((value, index) => (
                      <MenuItem
                        key={value || index}
                        value={value}
                        sx={{
                          fontSize: textSize
                            ? `${textSize}px !important`
                            : "12px !important",
                        }}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {subLabel ? (
                  <span className="text-[10px] ml-1">{subLabel}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default DropDown;
