import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import { linkButton } from "../../Visits/ConfigureNavigations";
import RichTextEditor from "../../../../../components/RichTextEditor";
import {
  createChoiceService,
  getAllQuestionnaireDropdownsDataService,
  getChoiceByIdDataService,
  updateChoiceService,
} from "./../Questionnaire";
import { getQuestionaireId } from "../../../../../services/calculation";

export const selectStyles = {
  width: 530,
  marginTop: 1,
};

const CreateChoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fieldId, id } = location.state || {};
  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [editorContent, setEditorContent] = useState("");
  const [clearOtherResponses, setClearOtherResponses] = useState(false);
  const [allowExpand, setAllowExpand] = useState(false);
  const [textAlignment, setTextAlignment] = useState();
  const [language, setLanguage] = useState();

  const [questionnaireTypeData, setQuestionnaireTypeData] = useState([]);
  const [choiceByIdData, setChoiceByIdData] = useState({});
  const [languagesOptions, setLanguagesOptions] = useState([]);

  const textAlignData = [
    { id: "1", name: "Left" },
    { id: "2", name: "Center" },
    { id: "3", name: "Right" },
  ];
  const notify = () =>
    toast.success("Questionnaire Created Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const createQuestionnaireSchema = yup.object().shape({
    optionTranslationKey: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createQuestionnaireSchema),
  });

  useEffect(() => {
    getAllDropdownData();
    getChoiceByIdData();
    getQuestionaireIdData();
  }, []);

  useEffect(() => {
    if (choiceByIdData) {
      setValue("optionTranslationKey", choiceByIdData.optionTranslationKey);
      setValue("value", choiceByIdData.value);
      setValue("sequence", choiceByIdData.sequence);
      setValue("OptionGroupKey", choiceByIdData.optionGroupKey);
      setValue("width", choiceByIdData.width);
      setValue("TextSize", choiceByIdData.textSize);
      setValue("TextAlignment", choiceByIdData.textAlignment);
      setClearOtherResponses(choiceByIdData.clearOtherResponses);
      setAllowExpand(choiceByIdData.allowExpand);
      setTextAlignment(choiceByIdData.textAlignment);
      setLanguage(
        choiceByIdData.language
          ? choiceByIdData.language
          : languagesOptions[0]?.value || ""
      );
      setValue(
        "ClearOtherResponses",
        choiceByIdData.clearOtherResponses ?? false
      );
      setValue(
        "Language",
        choiceByIdData.language
          ? choiceByIdData.language
          : languagesOptions[0]?.value || ""
      );
      setValue("allowExpand", choiceByIdData.allowExpand ?? false);
      setValue("studyFileId", choiceByIdData.studyFileId);
      setEditorContent(choiceByIdData.choiceDisplayText);
    }
  }, [choiceByIdData, setValue, languagesOptions]);

  const getQuestionaireIdData = async () => {
    try {
      const res = await getQuestionaireId();
      if (res.status) {
        setLanguagesOptions(res.data.result.languageList || []);
      }
    } catch (err) {
      console.error("Error: ", err.message);
    }
  };

  const stripPTags = (html) => {
    const cleanHtml = html.replace(/<\/?p[^>]*>/gi, "");
    return cleanHtml;
  };

  const getAllDropdownData = async () => {
    setLoad(true);
    try {
      const res = await getAllQuestionnaireDropdownsDataService();
      if (res?.status) {
        setLoad(false);
        setQuestionnaireTypeData(res?.data?.result?.allQuestionnaireTypes);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const handleNavigation = () => {
    navigate("/study-management/manage-choices", {
      state: { fieldId: fieldId },
    });
  };

  const getChoiceByIdData = async () => {
    setLoad(true);
    try {
      const res = await getChoiceByIdDataService(id);
      if (res?.status) {
        setLoad(false);
        setChoiceByIdData(res?.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const onSubmit = async (data) => {
    let newData = {
      questionId: fieldId,
      optionTranslationKey: data?.optionTranslationKey,
      choiceDisplayText: editorContent,
      value: parseFloat(data?.value),
      sequence: parseFloat(data?.sequence),
      optionGroupKey: data?.OptionGroupKey,
      allowExpand: data?.allowExpand,
      width: parseFloat(data?.width),
      clearOtherResponses: data?.ClearOtherResponses,
      textAlignment: data?.TextAlignment,
      leftPosition: 0,
      topPosition: 0,
      studyFileId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      language: data?.Language,
      textSize: data?.TextSize,
      id: id ? id : "",
    };

    try {
      setLoad(true);
      setShowDialog(false);

      // Make the appropriate API call based on whether 'id' exists
      const apiCall = id
        ? await updateChoiceService(newData)
        : await createChoiceService(newData);

      if (apiCall && apiCall.status) {
        setLoad(false);
        notify();
        navigate(`/study-management/manage-choices`, {
          state: {
            fieldId: fieldId,
          },
        });
      } else {
        setLoad(false);
        requestFailed("API call failed");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/manage-choices");
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <button onClick={handleNavigation}>
                <Link>Manage Choice</Link>
              </button>{" "}
              |{" "}
              <button
                onClick={() => {
                  navigate(`/${id ? "update" : "create"}-choices`, {
                    state: id
                      ? { id: id, fieldId: fieldId }
                      : { fieldId: fieldId },
                  });
                }}
              >
                <Link>{id ? "Update Choice" : "Create Choice"}</Link>
              </button>{" "}
            </p>
            <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Col md={6}>
                <div className="study-management-head-start">
                  <p className="study-management-heading">
                    {id ? "Update Choice" : "Create Choice"}
                  </p>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box
              component="form"
              sx={{
                height: "auto",
                width: "100%",
              }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <Row style={rowStyles}>
                <Col md={2}>
                  <div>
                    <b>QuestionId</b>
                  </div>
                </Col>
                <Col md={10}>
                  <TextField
                    value={fieldId}
                    variant="outlined"
                    disabled
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-16">
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    ChoiceDisplayText
                  </p>
                </Col>
                <Col md={10} lg={6}>
                  <RichTextEditor
                    value={editorContent}
                    onChange={(content) => {
                      setEditorContent(content);
                      setShowDialog(true);
                    }}
                    className="mb-16 w-1/2"
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2} className="mt-16">
                  <p className="descriptionLabel" style={labelStyles}>
                    OptionTranslationKey
                  </p>
                </Col>
                <Col md={10} className="mt-16">
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("optionTranslationKey", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.optionTranslationKey && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.optionTranslationKey.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Value
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    type="number"
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("value", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Sequence
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    type="number"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("sequence", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    OptionGroupKey
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("OptionGroupKey", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Width
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    type="number"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("width", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    TextSize
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    type="number"
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("TextSize", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    TextAlignment
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      value={getValues("TextAlignment") || []}
                      name="TextAlignment"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("TextAlignment", {
                        onChange: (e) => {
                          setValue("TextAlignment", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {textAlignData &&
                        textAlignData?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    ClearOtherResponses
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    checked={getValues("ClearOtherResponses") ?? false}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("ClearOtherResponses", {
                      onChange: (e) => {
                        const isChecked = e.target.checked;
                        setValue("ClearOtherResponses", isChecked, {
                          shouldValidate: true,
                        });
                        setClearOtherResponses(isChecked);
                        setShowDialog(true);
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Language
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      value={getValues("Language") || []}
                      name="Language"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("Language", {
                        onChange: (e) => {
                          setValue("Language", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {languagesOptions &&
                        languagesOptions?.map((item, index) => (
                          <MenuItem value={item.value} key={item.value}>
                            {index + 1 + ". " + item.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Study File Id
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="studyFileId"
                      value={getValues("studyFileId") === undefined ? "" : getValues("studyFileId")}
                      disabled
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("studyFileId", {
                        onChange: (e) => {
                          setValue("studyFileId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>Select File</em>
                      </MenuItem>
                      {questionnaireTypeData &&
                        questionnaireTypeData.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    AllowExpand
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    checked={getValues("allowExpand") ?? false}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("allowExpand", {
                      onChange: (e) => {
                        const isChecked = e.target.checked;
                        setValue("allowExpand", isChecked, {
                          shouldValidate: true,
                        }); // Update form state
                        setAllowExpand(isChecked); // Update local state
                        setShowDialog(true); // Show dialog
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={8}></Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    <button
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      className="study-management-cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="study-management-head-end ml-4">
                    <button
                      type="submit"
                      className="study-management-create-btn-md"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default CreateChoices;
