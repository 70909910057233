import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaTrashAlt,
  FaPencilAlt,
  FaCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import {
  copyQuestionnaireService,
  deleteChoiceService,
  deleteQuestionnaireService,
  getAllManageChoiceDataSevice,
  getAllQuestionnaireDataSevice,
} from "../Questionnaire";
import DeleteModal from "../../../../../components/DeleteModal";
import DOMPurify from "dompurify";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export const linkButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  backgroundColor: "#337ab7",
  padding: "8px",
  height: "35px",
  border: "none",
  color: "white",
  borderRadius: "5px",
  fontSize: "10px",
};

export const addButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  color: "#337ab7",
  backgroundColor: "white",
  padding: "8px",
  // fontSize: "15px",
  height: "35px",
  border: "2px solid #337ab7",
  borderRadius: "5px",
};

const ManageChoice = () => {
  const location = useLocation();
  const { fieldId, questionDataId } = location.state || {};

  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const [ChoiceData, setChoiceData] = useState([]);

  const [pageSize, setPageSize] = useState(5);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [copyId, setCopyId] = useState(null);

  const deleteNotify = () =>
    toast.success("Choice Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const copyNotify = () =>
    toast.success("Choice Copied Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  useEffect(() => {
    fetchManageChoice(fieldId);
  }, []);

  const fetchManageChoice = async (fieldId) => {
    try {
      setLoad(true);
      const res = await getAllManageChoiceDataSevice(fieldId);
      if (res.status) {
        setLoad(false);
        setChoiceData(res.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const choiceDatarows = ChoiceData.map((row) => ({
    id: row.id,
    optionTranslationKey: row.optionTranslationKey,
    choiceDisplayText: DOMPurify.sanitize(row.choiceDisplayText),
    value: row.value,
    sequence: row.sequence,
    delete: row.id,
    optionGroupKey: row.optionGroupKey,
    clearOtherResponses: row.clearOtherResponses,
    leftPosition: row.leftPosition,
    width: row.width,
    questionId: row.questionId,
    studyFileId: row.studyFileId,
    textAlignment: row.textAlignment,
    allowExpand: row.allowExpand,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      minWidth: 100,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "OptionTranslationKey",
      headerName: "OptionTranslationKey",
      minWidth: 220,
      renderHeader: () => (
        <div className="grid-heading">{"OptionTranslationKey"}</div>
      ),
      renderCell: (params) => {
        const optionTranslationKey = params.row.optionTranslationKey;
        return (
          <div className="grid-body">
            <p>{optionTranslationKey}</p>
          </div>
        );
      },
    },
    {
      field: "ChoiceDisplayText",
      headerName: "ChoiceDisplayText",
      minWidth: 170,
      renderHeader: () => (
        <div className="grid-heading">{"ChoiceDisplayText"}</div>
      ),
      renderCell: (params) => {
        const choiceDisplayText = params.row.choiceDisplayText;
        return (
          <div className="grid-body">
            <span dangerouslySetInnerHTML={{ __html: choiceDisplayText }} />
          </div>
        );
      },
    },
    {
      field: "Value",
      headerName: "Value",
      minWidth: 80,
      renderHeader: () => <div className="grid-heading">{"Value"}</div>,
      renderCell: (params) => {
        const value = params.row.value;
        return (
          <div className="grid-body">
            <p>{value}</p>
          </div>
        );
      },
    },
    {
      field: "Sequence",
      headerName: "Sequence",
      minWidth: 100,
      renderHeader: () => <div className="grid-heading">{"Sequence"}</div>,
      renderCell: (params) => {
        const sequence = params.row.sequence;
        return (
          <div className="grid-body">
            <p>{sequence}</p>
          </div>
        );
      },
    },
    {
      field: "OptionGroupKey",
      headerName: "OptionGroupKey",
      minWidth: 200,
      renderHeader: () => (
        <div className="grid-heading">{"OptionGroupKey"}</div>
      ),
      renderCell: (params) => {
        const optionGroupKey = params.row.optionGroupKey;
        return (
          <div className="grid-body">
            <p>{optionGroupKey}</p>
          </div>
        );
      },
    },
    {
      field: "ClearOtherResponses",
      headerName: "ClearOtherResponses",
      minWidth: 160,
      renderHeader: () => (
        <div className="grid-heading">{"ClearOtherResponses"}</div>
      ),
      renderCell: (params) => {
        const clearOtherResponses = params.row.clearOtherResponses;
        return (
          <div className="grid-body">
            {clearOtherResponses ? <FaCheckSquare /> : <FaRegSquare />}
          </div>
        );
      },
    },
    {
      field: "AllowExpand",
      headerName: "AllowExpand",
      minWidth: 130,
      renderHeader: () => <div className="grid-heading">{"AllowExpand"}</div>,
      renderCell: (params) => {
        const allowExpand = params.row.allowExpand;
        return (
          <div className="grid-body">
            {allowExpand ? <FaCheckSquare /> : <FaRegSquare />}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderHeader: () => <div className="grid-heading">{"Action"}</div>,
      renderCell: (params) => {
        const id = params?.row.id;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <button
              style={linkButtonStyle}
              onClick={() => {
                navigate(`/study-management/update-choices`, {
                  state: {
                    id: id,
                    fieldId: fieldId,
                  },
                });
              }}
            >
              <FaPencilAlt color="white" size={15} />
            </button>

            <button
              onClick={() => {
                setDeleteId(id);
                setShowConfirmModal(true);
              }}
              style={{
                cursor: "pointer",
                textAlign: "center",
                background: "red",
                border: "none",
                borderRadius: "5px",
                padding: "10px 10px",
              }}
            >
              <FaTrashAlt
                color="white"
                style={{
                  fontSize: "15px",
                }}
              />
            </button>
          </div>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteChoiceService(deleteId);

      if (res.status) {
        setLoad(false);
        fetchManageChoice(fieldId);
        setShowConfirmModal(false);
        deleteNotify();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };
  
  const handleNavigation = () => {
    navigate("/study-management/questionnaires/form-builder", {
      state: { id: questionDataId },
    });
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <button onClick={handleNavigation}>
              <Link>Edit Questionaire</Link>
            </button>{" "}
            |{" "}
            <button
              onClick={() => {
                navigate("/study-management/manage-choices", {
                  state: {
                    fieldId: fieldId,
                  },
                });
              }}
            >
              <Link>Manage Choice</Link>
            </button>
            -
            {/* <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b> */}
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Manage Choices</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="study-management-head-end">
                <button
                  className="study-management-create-btn-md"
                  onClick={() => {
                    navigate(`/study-management/create-choices`, {
                      state: {
                        fieldId: fieldId,
                      },
                    });
                  }}
                >
                  Create Choices
                </button>
              </div>
            </Col>
          </Row>

          <Box sx={{ height: 400, width: "100%", display: "flex" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allQuestionnaire-grid"
                rows={choiceDatarows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>

          {/* <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row> */}
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Choices"}
        />
      )}
    </>
  );
};

export default ManageChoice;
