import React, { useEffect } from "react";
import { Registry } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import "./QuestionnaireCss.css";
import AgeRange from "../../../../../components/FormComponents/AgeRange";
import AgeRangeYear from "../../../../../components/FormComponents/AgeRangeYear";
import AssignedDrugKit from "../../../../../components/FormComponents/AssignedDrugKit";
import Camera from "../../../../../components/FormComponents/Camera";
import CheckBox from "../../../../../components/FormComponents/CheckBox";
import Date from "../../../../../components/FormComponents/Date";
import Time from "../../../../../components/FormComponents/Time";
import DispenseDrugKit from "../../../../../components/FormComponents/DispenseDrugKit";
import DobAge from "../../../../../components/FormComponents/DobAge";
import DropDown from "../../../../../components/FormComponents/DropDown";
import DurationSpinner from "../../../../../components/FormComponents/DurationSpinner";
import EQ5D5L from "../../../../../components/FormComponents/EQ5D5L";
import HotSpot from "../../../../../components/FormComponents/HotSpot";
import ListView from "../../../../../components/FormComponents/ListView";
import MultiSelectCheckBox from "../../../../../components/FormComponents/MultiSelectCheckBox";
import None from "../../../../../components/FormComponents/None";
import NRS from "../../../../../components/FormComponents/NRS";
import NumberSpinnerNumericPad from "../../../../../components/FormComponents/NumberSpinnerNumericPad";
import NumberSpinner from "../../../../../components/FormComponents/NumberSpinner";
import NumericPad from "../../../../../components/FormComponents/NumericPad";
import PatientVisit from "../../../../../components/FormComponents/PatientVisit";
import PriorityList from "../../../../../components/FormComponents/PriorityList";
import RadioButton from "../../../../../components/FormComponents/RadioButton";
import RadioButtonGroup from "../../../../../components/FormComponents/RadioButtonGroup";
import RadioButtonImage from "../../../../../components/FormComponents/RadioButtonImage";
import ReplaceDrugKit from "../../../../../components/FormComponents/ReplaceDrugKit";
import SingleSelectCheckbox from "../../../../../components/FormComponents/SingleSelectCheckbox";
import StandardSpinnerControl from "../../../../../components/FormComponents/StandardSpinnerControl";
import TextArea from "../../../../../components/FormComponents/TextArea";
import TimeTwo from "../../../../../components/FormComponents/TimeTwo";
import VAS from "../../../../../components/FormComponents/VAS";
import VideoPlayer from "../../../../../components/FormComponents/VideoPlayer";
import VisitDatePicker from "../../../../../components/FormComponents/VisitDatePicker";
import PageContainer from "../../../../../components/FormComponents/PageContainer";
import PageBreak from "../../../../../components/FormComponents/PageBreak";
import Label from "../../../../../components/FormComponents/Label";
import Paragraph from "../../../../../components/FormComponents/Paragraph";
import HeadingOne from "../../../../../components/FormComponents/HeadingOne";
import HeadingTwo from "../../../../../components/FormComponents/HeadingTwo";
import HeadingThree from "../../../../../components/FormComponents/HeadingThree";
// import Layout from "../../components/Layout/Layout";

import HorizontalLine from "../../../../../components/FormComponents/HorizontalLine";
import QuestionnaireBuilder from "./QuestionnaireBuilder";

// import QuestionnaireBuilder from "./QuestionnaireBuilder";
// import EditQuestionnaire from "./EditQuestionnaire";
// import ViewQuestionnaire from "./ViewQuestionnaire";

const QuestionnaireComponent = ({ active }) => {
  const RegistryList = Registry.list();
  const registerComponent = (name, component) => {
    if (!RegistryList.includes(name)) {
      Registry.register(name, component);
    }
  };

  useEffect(() => {
    registerComponent("HorizontalLine", HorizontalLine);
    registerComponent("PageContainer", PageContainer);
    registerComponent("AgeRange", AgeRange);
    registerComponent("AgeRangeYear", AgeRangeYear);
    registerComponent("AssignedDrugKit", AssignedDrugKit);
    registerComponent("Camera", Camera);
    registerComponent("CheckBox", CheckBox);
    registerComponent("Date", Date);
    registerComponent("Time", Time);
    registerComponent("DispenseDrugKit", DispenseDrugKit);
    registerComponent("DobAge", DobAge);
    registerComponent("DropDown", DropDown);
    registerComponent("DurationSpinner", DurationSpinner);
    registerComponent("EQ5D5L", EQ5D5L);
    registerComponent("HotSpot", HotSpot);
    registerComponent("ListView", ListView);
    registerComponent("MultiSelectCheckBox", MultiSelectCheckBox);
    registerComponent("None", None);
    registerComponent("NRS", NRS);
    registerComponent("NumberSpinnerNumericPad", NumberSpinnerNumericPad);
    registerComponent("NumberSpinner", NumberSpinner);
    registerComponent("NumericPad", NumericPad);
    registerComponent("PatientVisit", PatientVisit);
    registerComponent("PriorityList", PriorityList);
    registerComponent("RadioButton", RadioButton);
    registerComponent("RadioButtonGroup", RadioButtonGroup);
    registerComponent("RadioButtonImage", RadioButtonImage);
    registerComponent("ReplaceDrugKit", ReplaceDrugKit);
    registerComponent("SingleSelectCheckbox", SingleSelectCheckbox);
    registerComponent("StandardSpinnerControl", StandardSpinnerControl);
    registerComponent("TextArea", TextArea);
    registerComponent("TimeTwo", TimeTwo);
    registerComponent("VAS", VAS);
    registerComponent("VideoPlayer", VideoPlayer);
    registerComponent("VisitDatePicker", VisitDatePicker);
    registerComponent("PageBreak", PageBreak);
    registerComponent("Label", Label);
    registerComponent("Paragraph", Paragraph);
    registerComponent("HeadingOne", HeadingOne);
    registerComponent("HeadingTwo", HeadingTwo);
    registerComponent("HeadingThree", HeadingThree);
  }, []);

  const handleActivePage = (path) => {
    switch (path) {
      case "Questionnaire Builder":
        return <QuestionnaireBuilder />;
      case "Edit Questionnaire":
        return <Layout children={<EditQuestionnaire />} />;
      case "View Questionnaire":
        return <Layout children={<ViewQuestionnaire />} />;
      default:
        return null;
    }
  };
  return <div>{handleActivePage(active)}</div>;
};

export default QuestionnaireComponent;
