import React, { createContext, useState, useContext } from 'react';

const AlignmentContext = createContext();

export const AlignmentProvider = ({ children }) => {
  const [alignment, setAlignment] = useState("top");

  return (
    <AlignmentContext.Provider value={{ alignment, setAlignment }}>
      {children}
    </AlignmentContext.Provider>
  );
};

export const useAlignment = () => useContext(AlignmentContext);
