import axios from "axios";

const calcServer = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_URL,
  timeout: 100000,
  // headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

// export const createQuestion = async (formSubmissionArray, questionnaireId) => {
//   const payload = formSubmissionArray.map((formSubmission, index) => ({
    
//     json: JSON.stringify(formSubmission),
//     questionTypeId: formSubmission.QuestionTypeId
//       ? parseInt(formSubmission.QuestionTypeId, 10)
//       : 1,
//     questionTranslationKey: formSubmission.id,
//     description: formSubmission.Description,
//     sequence: formSubmission.sequence,
//     diaryPageId: formSubmission.DiaryPage,
//     groupNumber: formSubmission.groupNumber,
//     minValue: formSubmission.minValue || 0,
//     maxValue: formSubmission.maxValue || 0,
//     displayAlignment: formSubmission.displayAlignment,
//     isActive: Boolean(formSubmission.isActive),
//     isRequired: Boolean(formSubmission.required),
//     width: formSubmission.width,
//     marginTop: formSubmission.marginTop,
//     marginLeft: formSubmission.marginLeft,
//     marginBottom: formSubmission.marginBottom,
//     marginRight: formSubmission.marginRight,
//     textSize: formSubmission.textSize,
//     patientAttributeConfigurationDetailId:
//       formSubmission.PatientAttributeConfiguration,
//     submitActionParameterId: formSubmission.SubmitActionParameter,
//     inputDisplayText: formSubmission.InputDisplayText,
//     exportDisplayOrder: formSubmission.ExportDisplayOrder,
//     exportDisplayName: formSubmission.ExportDisplayName,
//     choiceDisplayTypeId: formSubmission.ChoiceDisplayTypeId,
//     isLandscapeOrientation: Boolean(formSubmission.IsLandscapeOrientation),
//     minValueTextKey: formSubmission.id,
//     isInstruction: Boolean(formSubmission.IsInstruction),
//     enabledBusinessRuleId: formSubmission.EnableBusinessRuleId,
//     textAlignment: formSubmission.displayAlignment,
//     autoResizeChoices: Boolean(formSubmission.autoResizeChoices) || false,
//     maxValueDisplayText: formSubmission.MaxValueDisplayText,
//     summaryDisplayText: formSubmission.SummaryDisplayText,
//     isDirty: Boolean(formSubmission.dirty),
//     minValueDisplayText: formSubmission.MinValueTextKeyText,
//     maxValueTextKey: formSubmission.id,
//     choiceOrientation: formSubmission.KeepQuestionTextFixed,
//     visibleBusinessRuleId: formSubmission.VisibleBusinessRuleId,
//     questionnaireId: questionnaireId,
//     columnCount: formSubmission.count,
//     summaryDisplayTextKey: formSubmission.id,
//     inputFieldTypeId: formSubmission.InputFieldTypeId
//       ? parseInt(formSubmission.InputFieldTypeId, 10)
//       : 1,
//     questionDisplayText: formSubmission.props ? formSubmission.props.label : "",
//     syncVersion: formSubmission.syncVersion,
//     dataPointId: formSubmission.dataPointId,
//     externalId: formSubmission.externalId,
//     paddingCharacters: formSubmission.paddingCharacters,
//     paddingLocation: formSubmission.paddingLocation,
//     isSticky: Boolean(formSubmission.isSticky),
//     displayOnConfirmation: Boolean(formSubmission.displayOnConfirmation),
//     orderValue: index + 1,
//   }));
//   const config = {
//     method: "post",
//     url: `${process.env.REACT_APP_IDENTITY_URL}/app/Question/CreateQuestion`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: payload,
//   };

//   try {
//     const response = await axios(config);
//     console.log("API Response: ", response.data);
//     return response;
//   } catch (error) {
//     console.error("API Error: ", error);
//     throw error;
//   }
// };

export const createQuestion = async (formSubmissionArray, questionnaireId) => {
  const payload = formSubmissionArray.map((formSubmission, index) => {
    const props = {
      ...formSubmission.props,
    };
    if (formSubmission.props?.set && typeof formSubmission.props.set === "function") {
      props.set = formSubmission.props.set.toString();
    } else {
      delete props.set;
    }
    const dataWithStringifiedFunctions = {
      ...formSubmission,
      props,
    };

    return {
      json: JSON.stringify(dataWithStringifiedFunctions),  // Use dataWithStringifiedFunctions instead of formSubmission
      questionTypeId: formSubmission.QuestionTypeId
        ? parseInt(formSubmission.QuestionTypeId, 10)
        : 1,
      questionTranslationKey: formSubmission.id,
      description: formSubmission.Description,
      sequence: formSubmission.sequence,
      diaryPageId: formSubmission.DiaryPage,
      groupNumber: formSubmission.groupNumber,
      minValue: formSubmission.minValue || 0,
      maxValue: formSubmission.maxValue || 0,
      displayAlignment: formSubmission.displayAlignment,
      isActive: Boolean(formSubmission.isActive),
      isRequired: Boolean(formSubmission.required),
      width: formSubmission.width,
      marginTop: formSubmission.marginTop,
      marginLeft: formSubmission.marginLeft,
      marginBottom: formSubmission.marginBottom,
      marginRight: formSubmission.marginRight,
      textSize: formSubmission.textSize,
      patientAttributeConfigurationDetailId: formSubmission.PatientAttributeConfiguration,
      submitActionParameterId: formSubmission.SubmitActionParameter,
      inputDisplayText: formSubmission.InputDisplayText,
      exportDisplayOrder: formSubmission.ExportDisplayOrder,
      exportDisplayName: formSubmission.ExportDisplayName,
      choiceDisplayTypeId: formSubmission.ChoiceDisplayTypeId,
      isLandscapeOrientation: Boolean(formSubmission.IsLandscapeOrientation),
      minValueTextKey: formSubmission.id,
      isInstruction: Boolean(formSubmission.IsInstruction),
      enabledBusinessRuleId: formSubmission.EnableBusinessRuleId,
      textAlignment: formSubmission.displayAlignment,
      autoResizeChoices: Boolean(formSubmission.autoResizeChoices) || false,
      maxValueDisplayText: formSubmission.MaxValueDisplayText,
      summaryDisplayText: formSubmission.SummaryDisplayText,
      isDirty: Boolean(formSubmission.dirty),
      minValueDisplayText: formSubmission.MinValueTextKeyText,
      maxValueTextKey: formSubmission.id,
      choiceOrientation: formSubmission.KeepQuestionTextFixed,
      visibleBusinessRuleId: formSubmission.VisibleBusinessRuleId,
      questionnaireId: questionnaireId,
      columnCount: formSubmission.count,
      summaryDisplayTextKey: formSubmission.id,
      inputFieldTypeId: formSubmission.InputFieldTypeId
        ? parseInt(formSubmission.InputFieldTypeId, 10)
        : 1,
      questionDisplayText: formSubmission.props ? formSubmission.props.label : "",
      syncVersion: formSubmission.syncVersion,
      dataPointId: formSubmission.dataPointId,
      externalId: formSubmission.externalId,
      paddingCharacters: formSubmission.paddingCharacters,
      paddingLocation: formSubmission.paddingLocation,
      isSticky: Boolean(formSubmission.isSticky),
      displayOnConfirmation: Boolean(formSubmission.displayOnConfirmation),
      orderValue: index + 1,
    };
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_IDENTITY_URL}/app/Question/CreateQuestion`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  try {
    const response = await axios(config);
    console.log("API Response: ", response.data);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const updateQuestion = async (
  formSubmissionArray,
  questionIdData,
  questionnaireId,
  oldData,
  deletedItem = null
) => {
  const payload = formSubmissionArray.map((formSubmission, index) => {
    console.log("Form Sub", formSubmission, oldData);
    const props = {
      ...formSubmission.props,
    };
    if (formSubmission.props?.set && typeof formSubmission.props.set === "function") {
      props.set = formSubmission.props.set.toString();
    } else {
      delete props.set;
    }
    const dataWithStringifiedFunctions = {
      ...formSubmission,
      props,
    };
    return {
      json: JSON.stringify(dataWithStringifiedFunctions),
      questionTypeId: formSubmission.QuestionTypeId
        ? parseInt(formSubmission.QuestionTypeId, 10)
        : 1,
      questionTranslationKey: formSubmission.id,
      description: formSubmission.Description,
      sequence: formSubmission.sequence,
      diaryPageId: formSubmission.DiaryPage,
      groupNumber: formSubmission.groupNumber,
      minValue: formSubmission.minValue || 0,
      maxValue: formSubmission.maxValue || 0,
      displayAlignment: formSubmission.displayAlignment,
      isActive: Boolean(formSubmission.isActive),
      isRequired: Boolean(formSubmission.required),
      width: formSubmission.width,
      marginTop: formSubmission.marginTop,
      marginLeft: formSubmission.marginLeft,
      marginBottom: formSubmission.marginBottom,
      marginRight: formSubmission.marginRight,
      textSize: formSubmission.textSize,
      patientAttributeConfigurationDetailId:
        formSubmission.PatientAttributeConfiguration,
      submitActionParameterId: formSubmission.SubmitActionParameter,
      inputDisplayText: formSubmission.InputDisplayText,
      exportDisplayOrder: formSubmission.ExportDisplayOrder,
      exportDisplayName: formSubmission.ExportDisplayName,
      choiceDisplayTypeId: formSubmission.ChoiceDisplayTypeId,
      isLandscapeOrientation: Boolean(formSubmission.IsLandscapeOrientation),
      minValueTextKey: formSubmission.id,
      isInstruction: Boolean(formSubmission.IsInstruction),
      enabledBusinessRuleId: formSubmission.EnableBusinessRuleId,
      textAlignment: formSubmission.displayAlignment,
      autoResizeChoices: Boolean(formSubmission.autoResizeChoices) || false,
      maxValueDisplayText: formSubmission.MaxValueDisplayText,
      summaryDisplayText: formSubmission.SummaryDisplayText,
      isDirty: Boolean(formSubmission.dirty),
      minValueDisplayText: formSubmission.MinValueTextKeyText,
      maxValueTextKey: formSubmission.id,
      choiceOrientation: formSubmission.KeepQuestionTextFixed,
      visibleBusinessRuleId: formSubmission.VisibleBusinessRuleId,
      questionnaireId: questionnaireId,
      columnCount: formSubmission.count,
      summaryDisplayTextKey: formSubmission.id,
      inputFieldTypeId: formSubmission.InputFieldTypeId
        ? parseInt(formSubmission.InputFieldTypeId, 10)
        : 1,
      questionDisplayText: formSubmission.props
        ? formSubmission.props.label
        : "",
      syncVersion: formSubmission.syncVersion,
      dataPointId: formSubmission.dataPointId,
      externalId: formSubmission.externalId,
      paddingCharacters: formSubmission.paddingCharacters,
      paddingLocation: formSubmission.paddingLocation,
      isSticky: Boolean(formSubmission.isSticky),
      displayOnConfirmation: Boolean(formSubmission.displayOnConfirmation),
      markRecordAsUpdated: deletedItem?.id === formSubmission?.id ? false : oldData?.some(item => JSON.parse(item?.json)?.id === formSubmission?.id && JSON.stringify(formSubmission) !== JSON.stringify(JSON.parse(item?.json))) || false,

      id: deletedItem
        ? oldData?.find(
            (item) => JSON.parse(item?.json)?.id === deletedItem?.id
          )?.id
        : oldData?.find(
            (item) => JSON.parse(item?.json)?.id === formSubmission?.id
          )?.id,
      orderValue:
        deletedItem !== null
          ? deletedItem?.id === formSubmission?.id
            ? 0
            : index + 1
          : index + 1,
      isANewRecord: oldData?.find(
        (item) => JSON.parse(item?.json)?.id === formSubmission?.id
      )
        ? false
        : true,
      markRecordAsDeleted:
        deletedItem !== null
          ? deletedItem?.id === formSubmission?.id
            ? true
            : false
          : false,
    };
  });
  const config = {
    method: "put",
    url: `${process.env.REACT_APP_IDENTITY_URL}/app/Question/api/SaveEditedData`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  try {
    const response = await axios(config);
    console.log("API Response: ", response.data);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const getQuestionByID = (QuestionnaireId) => {
  return new Promise((resolve, reject) => {
    calcServer
      .get(`app/Question/GetQuestionList?QuestionnaireId=${QuestionnaireId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const DeleteQuestionById = (QuestionnaireId) => {
  return new Promise((resolve, reject) => {
    calcServer
      .get(
        `app/Question/DeleteQuestionsByQuestionnaireId?QuestionnaireId=${QuestionnaireId}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
