import React, { useEffect, useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import history from "../../../../../hooks/history";
import SubmitQuestionnaire from "../SubmitQuestionnaire";
import * as variables from "./variables";
import FormElementsEdit from "./form-element-edit";
import "react-form-builder2/dist/app.css";
import { toast } from "react-toastify";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
// import "./QuestionnaireCss.css";
import AgeRange from "../../../../../components/FormComponents/AgeRange";
import AgeRangeYear from "../../../../../components/FormComponents/AgeRangeYear";
import AssignedDrugKit from "../../../../../components/FormComponents/AssignedDrugKit";
import Camera from "../../../../../components/FormComponents/Camera";
import CheckBox from "../../../../../components/FormComponents/CheckBox";
import Date from "../../../../../components/FormComponents/Date";
import Time from "../../../../../components/FormComponents/Time";
import DispenseDrugKit from "../../../../../components/FormComponents/DispenseDrugKit";
import DobAge from "../../../../../components/FormComponents/DobAge";
import DropDown from "../../../../../components/FormComponents/DropDown";
import DurationSpinner from "../../../../../components/FormComponents/DurationSpinner";
import EQ5D5L from "../../../../../components/FormComponents/EQ5D5L";
import HotSpot from "../../../../../components/FormComponents/HotSpot";
import ListView from "../../../../../components/FormComponents/ListView";
import MultiSelectCheckBox from "../../../../../components/FormComponents/MultiSelectCheckBox";
import None from "../../../../../components/FormComponents/None";
import NRS from "../../../../../components/FormComponents/NRS";
import NumberSpinnerNumericPad from "../../../../../components/FormComponents/NumberSpinnerNumericPad";
import NumberSpinner from "../../../../../components/FormComponents/NumberSpinner";
import NumericPad from "../../../../../components/FormComponents/NumericPad";
import PatientVisit from "../../../../../components/FormComponents/PatientVisit";
import PriorityList from "../../../../../components/FormComponents/PriorityList";
import RadioButton from "../../../../../components/FormComponents/RadioButton";
import RadioButtonGroup from "../../../../../components/FormComponents/RadioButtonGroup";
import RadioButtonImage from "../../../../../components/FormComponents/RadioButtonImage";
import ReplaceDrugKit from "../../../../../components/FormComponents/ReplaceDrugKit";
import SingleSelectCheckbox from "../../../../../components/FormComponents/SingleSelectCheckbox";
import StandardSpinnerControl from "../../../../../components/FormComponents/StandardSpinnerControl";
import TextArea from "../../../../../components/FormComponents/TextArea";
import TimeTwo from "../../../../../components/FormComponents/TimeTwo";
import VAS from "../../../../../components/FormComponents/VAS";
import VideoPlayer from "../../../../../components/FormComponents/VideoPlayer";
import VisitDatePicker from "../../../../../components/FormComponents/VisitDatePicker";
import PageBreak from "../../../../../components/FormComponents/PageBreak";
import Label from "../../../../../components/FormComponents/Label";
import Paragraph from "../../../../../components/FormComponents/Paragraph";
// import HeadingOne from "../FormComponents/HeadingOne";
// import HeadingTwo from "../FormComponents/HeadingTwo";
// import HeadingThree from "../FormComponents/HeadingThree";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import { useAlignment } from "./../../../../../context/alignmentFormbuilder/AlignmentContext";
import FormElementsEditAlter from "./form-element-edit-alter";
import {
  createQuestion,
  DeleteQuestionById,
  getQuestionByID,
  updateQuestion,
} from "../../../../../services/questionaireBuilder";
import BeatLoader from "react-spinners/BeatLoader";
import { rowStyles } from "../../SubjectAttributes/SubjectAttributes";
import DeleteModal from "../../../../../components/DeleteModal";

const QuestionnaireBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var id = location?.state?.id;
  var formNameCreated = location.state.formName;

  const [formData, setFromData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [questionIdData, setQuestionIdData] = useState([]);
  const [formId, setFormId] = useState(1);
  const [items, setItems] = useState([]);
  const [load, setLoad] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { alignment, setAlignment } = useAlignment();
  const [fieldValue, setFieldValue] = useState({ key: "", value: "" });

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  useEffect(() => {
    if (id) {
      getQuestionsByIdData(id);
    } else {
      console.warn("ID is not available for fetching question data.");
    }
  }, [id]);

  const alterArrayOptions = [
    "FieldSet",
    "Header",
    "Paragraph",
    "TwoColumnRow",
    "ThreeColumnRow",
  ];

  useEffect(() => {
    setItems([
      {
        key: "Header",
      },
      {
        key: "Paragraph",
      },
      {
        key: "FieldSet",
        canHaveAnswer: false,
        element: false,
        name: "FieldSet",
        label: "Field Set",
        icon: "fas fa-bars",
        field_name: "fieldset-element",
      },
      {
        key: "TwoColumnRow",
      },
      {
        key: "ThreeColumnRow",
      },
      {
        key: "PageBreak",
        element: "CustomElement",
        component: PageBreak,
        type: "custom",
        forwardRef: true,
        field_name: "visit_date_picker",
        name: "Page Break",
        icon: "fas fa-divide",
        props: { test: "page_break", customPageBreak: true, hide: false },
        label: "",
      },
      {
        key: "AgeRange",
        element: "CustomElement",
        component: AgeRange,
        type: "custom",
        forwardRef: true,
        field_name: "age_date_range",
        name: "Age Date Range Picker",
        icon: "fa fa-calendar",
        props: {
          test: "age_date_range",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Age Range",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "AgeRangeYear",
        element: "CustomElement",
        component: AgeRangeYear,
        type: "custom",
        forwardRef: true,
        field_name: "age_date_range_year",
        name: "Age Select For Year Only",
        icon: "fas fa-calendar-alt",
        props: {
          test: "age_date_range_year",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Age Range Year",
          labelAlignment: "top",
          fieldValue: fieldValue,
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "AssignedDrugKit",
        element: "CustomElement",
        component: AssignedDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "assigned_drug_kit",
        name: "Assigned Drug Kit Selector",
        icon: "fa fa-medkit",
        props: {
          test: "assigned_drug_kit",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          currentPage: "",
          label: "Assigned Drug Kit",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "Camera",
        element: "CustomElement",
        component: Camera,
        type: "custom",
        forwardRef: true,
        field_name: "camera",
        name: "Camera",
        icon: "fas fa-camera",
        props: {
          test: "camera",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Upload Image",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "CheckBox",
        element: "CustomElement",
        component: CheckBox,
        type: "custom",
        forwardRef: true,
        field_name: "checkbox",
        name: "CheckBox",
        icon: "fas fa-check-square",
        props: {
          test: "checkbox",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Checkbox",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "Date",
        element: "CustomElement",
        component: Date,
        type: "custom",
        forwardRef: true,
        field_name: "date",
        name: "date",
        icon: "far fa-calendar-alt",
        props: {
          test: "date",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Date",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "Time",
        element: "CustomElement",
        component: Time,
        type: "custom",
        forwardRef: true,
        field_name: "time",
        name: "Time",
        icon: "fas fa-clock",
        props: {
          test: "time",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Time",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "DispenseDrugKit",
        element: "CustomElement",
        component: DispenseDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "dispenseDrugKit",
        name: "Dispense Drug Kit Selector",
        icon: "fa fa-medkit",
        props: {
          test: "dispense_drug_kit",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Dispense Drug Kit",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "DobAge",
        element: "CustomElement",
        component: DobAge,
        type: "custom",
        forwardRef: true,
        field_name: "dob_age_selector",
        name: "Dob Age Selector",
        icon: "far fa-calendar-check",
        props: {
          test: "dob_age_selector",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Dob Age",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "DropDown",
        element: "CustomElement",
        component: DropDown,
        type: "custom",
        forwardRef: true,
        field_name: "drop_down",
        name: "Dropdown",
        icon: "fas fa-caret-square-down",
        props: {
          test: "drop_down",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Drop Down",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "DurationSpinner",
        element: "CustomElement",
        component: DurationSpinner,
        type: "custom",
        forwardRef: true,
        field_name: "duration-spinner",
        name: "Duration Spinner",
        icon: "fas fa-circle-notch",
        props: {
          test: "duration-spinner",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Duration Spinner",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "EQ5D5L",
        element: "CustomElement",
        component: EQ5D5L,
        type: "custom",
        forwardRef: true,
        field_name: "eq-5d-5l",
        name: "EQ-5D-5L",
        icon: "fas fa-plus",
        props: {
          test: "eq-5d-5l",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "EQ5D5L",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "HotSpot",
        element: "CustomElement",
        component: HotSpot,
        type: "custom",
        forwardRef: true,
        field_name: "hot-spot",
        name: "Hot Spot Multiple Select HotSpot Single Select",
        icon: "fas fa-rss",
        props: {
          test: "hot-spot",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Hot Spot",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "ListView",
        element: "CustomElement",
        component: ListView,
        type: "custom",
        forwardRef: true,
        field_name: "list-view",
        name: "ListView",
        icon: "fas fa-list-ul",
        props: {
          test: "list-view",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "List View",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "MultiSelectCheckBox",
        element: "CustomElement",
        component: MultiSelectCheckBox,
        type: "custom",
        forwardRef: true,
        field_name: "multi-select-check-box",
        name: "MultiSelect CheckBox Conrol",
        icon: "fas fa-check-circle",
        props: {
          test: "multi-select-check-box",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Multi Select CheckBox",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "None",
        element: "CustomElement",
        component: None,
        type: "custom",
        forwardRef: true,
        field_name: "none",
        name: "None",
        icon: "fa fa-ban",
        props: {
          test: "none",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "None",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "NRS",
        element: "CustomElement",
        component: NRS,
        type: "custom",
        forwardRef: true,
        field_name: "nrs",
        name: "NRS",
        icon: "fas fa-plus",
        props: {
          test: "nrs",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "NRS",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "NumberSpinnerNumericPad",
        element: "CustomElement",
        component: NumberSpinnerNumericPad,
        type: "custom",
        forwardRef: true,
        field_name: "number_spinner_numeric_pad",
        name: "NumberSpinner NumericPad",
        icon: "fas fa-plus",
        props: {
          test: "number_spinner_numeric_pad",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Number SpinnerNumeric Pad",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "NumberSpinner",
        element: "CustomElement",
        component: NumberSpinner,
        type: "custom",
        forwardRef: true,
        field_name: "number_spinner",
        name: "NumberSpinner",
        icon: "fas fa-spinner",
        props: {
          test: "number_spinner",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Number Spinner",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "NumericPad",
        element: "CustomElement",
        component: NumericPad,
        type: "custom",
        forwardRef: true,
        field_name: "numeric_pad",
        name: "Numeric Pad",
        icon: "fa fa-list-ol",
        props: {
          test: "numeric_pad",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Numeric Pad",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "PatientVisit",
        element: "CustomElement",
        component: PatientVisit,
        type: "custom",
        forwardRef: true,
        field_name: "patient_visit",
        name: "Patient Visit Drug Kit Status Selector",
        icon: "far fa-hospital",
        props: {
          test: "patient_visit",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Patient Visit",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "PriorityList",
        element: "CustomElement",
        component: PriorityList,
        type: "custom",
        forwardRef: true,
        field_name: "priority_list",
        name: "Priority List",
        icon: "fas fa-tasks",
        props: {
          test: "priority_list",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Priority List",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "RadioButton",
        element: "CustomElement",
        component: RadioButton,
        type: "custom",
        forwardRef: true,
        field_name: "radio_button",
        name: "RadioButton",
        icon: "far fa-dot-circle",
        props: {
          test: "radio_button",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Radio Button",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "RadioButtonGroup",
        element: "CustomElement",
        component: RadioButtonGroup,
        type: "custom",
        forwardRef: true,
        field_name: "radio-button-group",
        name: "Radio Button Group Header",
        icon: "fas fa-list-ul",
        props: {
          test: "radio-button-group",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Radio Button Group",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      // {
      //   key: "RadioButtonImage",
      //   element: "CustomElement",
      //   component: RadioButtonImage,
      //   type: "custom",
      //   forwardRef: true,
      //   field_name: "radio-button-image",
      //   name: "Radio Button Image",
      //   icon: "fas fa-file-image",
      //   props: {
      //     test: "radio-button-image",
      //     disabled: false,
      //     hide: false,
      //     borderTop: false,
      //     borderBottom: false,
      //     borderSides: false,
      //     label: "Radio Button Image",
      //     set: ({ key, value }) => {
      //       console.log(`Key: ${key}, Value: ${value}`);
      //     },
      //   },
      //   label: "",
      // },
      {
        key: "ReplaceDrugKit",
        element: "CustomElement",
        component: ReplaceDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "replace-drug-kit",
        name: "Replace Drug Kit Selector",
        icon: "fas fa-tablets",
        props: {
          test: "replace-drug-kit",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Replace Drug Kit",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "SingleSelectCheckbox",
        element: "CustomElement",
        component: SingleSelectCheckbox,
        type: "custom",
        forwardRef: true,
        field_name: "single-select-checkbox",
        name: "Single Select Checkbox Spirobank",
        icon: "far fa-check-circle",
        props: {
          test: "single-select-checkbox",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Single Select Checkbox",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "StandardSpinnerControl",
        element: "CustomElement",
        component: StandardSpinnerControl,
        type: "custom",
        forwardRef: true,
        field_name: "standard-spinner-control",
        name: "Standard Spinner Control Temperature Spinner",
        icon: "fas fa-sync",
        props: {
          test: "standard-spinner-control",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Standard Spinner Control",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "TextArea",
        element: "CustomElement",
        component: TextArea,
        type: "custom",
        forwardRef: true,
        field_name: "text-area",
        name: "TextArea",
        icon: "fa fa-font",
        props: {
          test: "text-area",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Text Area",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "TimeTwo",
        element: "CustomElement",
        component: TimeTwo,
        type: "custom",
        forwardRef: true,
        field_name: "time-two",
        name: "TimeTwo",
        icon: "fas fa-clock",
        props: {
          test: "time-two",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Time Two",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "VAS",
        element: "CustomElement",
        component: VAS,
        type: "custom",
        forwardRef: true,
        field_name: "vas",
        name: "VAS",
        icon: "fas fa-plus",
        props: {
          test: "vas",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "VAS",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "VideoPlayer",
        element: "CustomElement",
        component: VideoPlayer,
        type: "custom",
        forwardRef: true,
        field_name: "video_player",
        name: "Video Player",
        icon: "fa fa-play-circle",
        props: {
          test: "video_player",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Video Player",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
      {
        key: "VisitDatePicker",
        element: "CustomElement",
        component: VisitDatePicker,
        type: "custom",
        forwardRef: true,
        field_name: "visit_date_picker",
        name: "Visit Date Picker",
        icon: "fas fa-calendar-check",
        props: {
          test: "visit_date_picker",
          disabled: false,
          hide: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Visit Date Picker",
          labelAlignment: "top",
          set: ({ key, value }) => {
            console.log(`Key: ${key}, Value: ${value}`);
            setFieldValue({ key, value });
          },
        },
        label: "",
      },
    ]);
    setLoad(true);
    setMounted(true);
    // setShowDialog(true);
  }, []);

  function arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const align = (data) => {
    data &&
      data.forEach((element, i) => {
        if (element.parentId) {
          arraymove(data, i, element.parentIndex + element.col + 1);
        }
      });
    return data;
  };

  const notify = () =>
    toast.success("Form Creation Successful", {
      toastId: "form-creation",
    });

  const notifyDelete = (msg) =>
    toast.success(`${msg ? msg : "Questions Deleted Successfully"}`, {
      toastId: "form-creation",
    });

  const Deletenotify = (msg) =>
    toast.success(`${msg ? msg : "All Fields Deleted Successfully"}`, {
      toastId: "delete-creation",
    });

  const formEmpty = () =>
    toast.warn("Form Data Is Emtpy", {
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const formSubmission = align(formData);

  useEffect(() => {
    if (deletedItem) {
      setLoad(false);
      let alignedData = align([...formData, deletedItem]);
      deleteParticularField(alignedData);
    }
  }, [deletedItem]);

  const onSubmit = async () => {
    setLoad(false);
    try {
      setShowDialog(false);

      if (!formSubmission || Object.keys(formSubmission).length === 0) {
        formEmpty();
        return;
      }
      const response =
        countData.length > 0
          ? await updateQuestion(formSubmission, questionIdData, id, countData)
          : await createQuestion(formSubmission, id);

      if (response && response.status === 200) {
        notify();
        setLoad(true);
        getQuestionsByIdData(id);
      } else {
        setLoad(true);
        console.error("Unexpected API Response: ", response);
        requestFailed();
      }
    } catch (error) {
      setLoad(true);
      console.error("Form Submission Error: ", error);
      requestFailed();
    }
  };

  const deleteQuestionnaaireData = async () => {
    try {
      const res = await DeleteQuestionById(id);
      if (res.status) {
        Deletenotify(res?.data.message);
        getQuestionsByIdData(id);
        setShowConfirmModal(false);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setShowConfirmModal(false);
    }
  };

  const getQuestionsByIdData = async (id) => {
    setLoad(false);
    try {
      const res = await getQuestionByID(id);

      if (res.status) {
        const parsedResults = res.data.result.map((item) => {
          try {
            if (item.json && typeof item.json === "string") {
              const parsedJson = JSON.parse(item.json);
              if (
                parsedJson.props &&
                typeof parsedJson.props.set === "string"
              ) {
                parsedJson.props.set = ({ key, value }) => {
                  setFieldValue({ key, value });
                };
              }
              return {
                ...parsedJson,
                fieldId: item.id,
              };
            } else {
              console.warn("Invalid JSON format:", item.json);
              return item.json;
            }
          } catch (parseError) {
            console.error("Error parsing JSON for item:", item, parseError);
            return null;
          }
        });
        const savedAlignment = localStorage.getItem("labelAlignment");
        setAlignment(savedAlignment ? savedAlignment : "top");
        setFromData(parsedResults);
        setCountData(res?.data.result);
        setQuestionIdData(res?.data.result);
        setLoad(true);
      } else {
        setLoad(true);
        console.error("API responded with an unsuccessful status:", res);
      }
    } catch (err) {
      setLoad(true);
      console.error("Error fetching question data:", err);
    }
  };

  const onLoad = () => {
    return new Promise((resolve) => {
      resolve(formData);
    });
  };

  const onPost = (data) => {
    setShowDialog(true);
    setFromData(data?.task_data);
    // This is for deletion
    if (countData?.length > 0 && data?.task_data?.length < countData?.length) {
      let deletedItem = findMissingObject(countData, data?.task_data);
      if (deletedItem) {
        setDeletedItem(deletedItem);
      }
    }
  };

  const deleteParticularField = async (data) => {
    const res = await updateQuestion(
      data,
      questionIdData,
      id,
      countData,
      deletedItem
    );
    if (res && res.status === 200) {
      notifyDelete();
      setLoad(true);
      getQuestionsByIdData(id);
    } else {
      setLoad(true);
      console.error("Unexpected API Response: ", res);
      requestFailed();
    }
  };

  const formHandleBack = () => {
    if (showDialog) {
      const confirm = window.confirm(
        "You have unsaved changes, want to proceed ?"
      );
      if (confirm) {
        navigate("/study-management/questionnaires");
      }
    } else {
      navigate("/study-management/questionnaires");
    }
  };

  function findMissingObject(originalArray, alteredArray) {
    // Create a Set of IDs from the altered array for efficient lookup
    const alteredIds = new Set(alteredArray.map((obj) => obj.id));

    // Iterate through the original array to find the missing object
    for (const item of originalArray) {
      // Parse the JSON string inside the 'json' key
      const parsedItem = JSON.parse(item.json);

      // Check if the parsed ID is not in the altered array
      if (!alteredIds.has(parsedItem.id)) {
        return parsedItem; // Return the missing object
      }
    }

    return null; // Return null if no missing object is found
  }

  return (
    <>
      {load ? (
        <>
          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>
          <Row>
            <Col sm={3}>
              <div
                style={{
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                <p style={{ fontSize: "24px" }}>
                  <i
                    onClick={formHandleBack}
                    className="fas fa-arrow-left"
                    style={{
                      fontSize: "25px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>{" "}
                  Form Elements
                </p>
              </div>
            </Col>
            <Col sm={3} style={{ marginTop: "35px" }}>
              <SubmitQuestionnaire
                variables={variables}
                formId={id}
                fieldValue={fieldValue}
                formData={formData}
                formName={formNameCreated}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
              />
            </Col>
            <Col md={2} style={{ marginTop: "35px", float: "right" }}>
              <div>
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="rounded-md w-[14rem] h-[36px] bg-[rgb(62,76,244)] text-white h-[36px] w-[12rem]"
                >
                  {countData?.length > 0 ? "Update" : "Save"}
                </button>
              </div>
            </Col>
            {countData?.length > 0 && (
              <Col
                md={2}
                style={{ marginTop: "35px", float: "right", textAlign: "end" }}
              >
                <div>
                  <button
                    onClick={() => setShowConfirmModal(true)}
                    className="cursor-pointer text-center bg-transparent text-red-500 border-2 border-red-500 rounded-sm w-[14rem] h-[36px]"
                  >
                    Delete All
                  </button>
                </div>
              </Col>
            )}
          </Row>

          <div className="App mt-3">
            <div>
              <ReactFormBuilder
                onLoad={onLoad}
                data={formData}
                onPost={onPost}
                toolbarItems={items}
                key={formData}
                renderEditForm={(props) => (
                  <>
                    {alterArrayOptions.includes(props?.element?.element) ? (
                      <>
                        <FormElementsEditAlter
                          formId={formId}
                          {...props}
                          formData={formData}
                        />
                      </>
                    ) : (
                      <>
                        <FormElementsEdit
                          formId={formId}
                          setAlignment={setAlignment}
                          alignment={alignment}
                          {...props}
                          formData={formData}
                          questionId={id}
                        />
                      </>
                    )}
                  </>
                )}
              />
              {/* <ReactFormBuilder
                onLoad={onLoad}
                onPost={onPost}
                toolbarItems={items}
                renderEditForm={(props) => (
                  <FormElementsEdit
                    formId={formId}
                    {...props}
                    formData={formData}
                  />
                )}
              /> */}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={deleteQuestionnaaireData}
          subject={"All Fields"}
        />
      )}
    </>
  );
};

export default QuestionnaireBuilder;
