/* eslint-disable no-unused-vars */
// import React, { useState, useContext, useEffect } from "react";
// import { useMethods, useLocalStorage } from "react-use";

// export const GetRuleContext = React.createContext();
// export const SetRuleContext = React.createContext();

// const RuleContext = ({ children }) => {
//   const [localRules, setLocalRules] = useLocalStorage("Rules", {
//     form: {},
//     formResponse: {},
//   });

  const reducer = (Rules) => {
    return {
      init(state) {
        return { ...state };
      },
      setForm(form) {
        let formData = {};
        form.map((item) => {
          formData[item.id] = item;
        });
        const tempRule = { ...Rules };
        tempRule["form"] = formData;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      resetForm() {
        const tempRule = { ...Rules };
        tempRule["form"] = {};
        tempRule["formResponse"] = {};
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      update(category, key, value) {
        const tempRule = { ...Rules };
        if (!tempRule[category] || tempRule[category] == undefined) {
          tempRule[category] = {};
        }
        tempRule[category][key] = value;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      incrementCount(category, value) {
        const tempRule = { ...Rules };
        tempRule[category].count = value;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      setActive(value) {
        console.log("Setting active Rule", value);
        const tempRule = { ...Rules };
        tempRule.isActive = value;
        setLocalRules({ ...tempRule });
        return { ...tempRule };
      },
    };
  };
//   const [Rules, RuleActions] = useMethods(reducer, {});

//   const [shouldUpdate, triggerUpdate] = useState(false);

//   const updateRule = (Rule) => {
//     triggerUpdate(false);
//   };

//   useEffect(() => {
//     const { isActive, ...data } = Rules;
//     shouldUpdate && updateRule(data);
//   }, [shouldUpdate]);

//   useEffect(() => {}, [Rules, localRules]);

//   return (
//     <GetRuleContext.Provider value={{ Rules, triggerUpdate }}>
//       <SetRuleContext.Provider value={RuleActions}>
//         {children}
//       </SetRuleContext.Provider>
//     </GetRuleContext.Provider>
//   );
// };

// export default RuleContext;



/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useMethods, useLocalStorage } from "react-use";

export const GetRuleContext = React.createContext();
export const SetRuleContext = React.createContext();

const RuleContext = ({ children }) => {
  const [localRules, setLocalRules] = useLocalStorage("Rules", {
    form: {},
    formResponse: {},
    isActive: false,
  });

  const reducer = (Rules) => {
    return {
      init(state) {
        return { ...state };
      },
      setForm(form) {
        let formData = {};
        form.map((item) => {
          formData[item.id] = item;
        });
        const tempRule = { ...Rules };
        tempRule["form"] = formData;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      resetForm() {
        const tempRule = { ...Rules };
        tempRule["form"] = {};
        tempRule["formResponse"] = {};
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      update(category, key, value) {
        const tempRule = { ...Rules };
        if (!tempRule[category] || tempRule[category] == undefined) {
          tempRule[category] = {};
        }
        tempRule[category][key] = value;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      incrementCount(category, value) {
        const tempRule = { ...Rules };
        tempRule[category].count = value;
        setLocalRules(tempRule);
        return { ...tempRule };
      },
      setActive(value) {
        console.log("Setting active Rule", value);
        const tempRule = { ...Rules };
        tempRule.isActive = value;
        setLocalRules({ ...tempRule });
        return { ...tempRule };
      },
    };
  };

  // Use useMethods hook to get Rules state and dispatch actions
  const [Rules, RuleActions] = useMethods(reducer, {
    form: {},
    formResponse: {},
    isActive: false,
  });

  const [shouldUpdate, triggerUpdate] = useState(false);

  const updateRule = (Rule) => {
    triggerUpdate(false);
  };

  useEffect(() => {
    const { isActive, ...data } = Rules;
    shouldUpdate && updateRule(data);
  }, [shouldUpdate]);

  useEffect(() => {}, [Rules, localRules]);

  return (
    <GetRuleContext.Provider value={{ Rules, triggerUpdate }}>
      <SetRuleContext.Provider value={RuleActions}>
        {children}
      </SetRuleContext.Provider>
    </GetRuleContext.Provider>
  );
};

export default RuleContext;
