import React from 'react';
import { useNavigate } from 'react-router-dom';

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

export default withNavigate;


export const drugReturnChoicesData = [
  {
    id: 1,
    name: "Assigned To Subject",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 2,
    name: "Available At Depot",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 3,
    name: "Available At Site",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 4,
    name: "Damaged",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 5,
    name: "Destroyed At Site",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 6,
    name: "Expired",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 7,
    name: "In Transit",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 8,
    name: "Lost By Subject",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 9,
    name: "Missing",
    enablePillCount: false,
    enableAttributes: false,
  },
  {
    id: 10,
    name: "Missing At Site",
    enablePillCount: false,
    enableAttributes: false,
  },
];

export const drugCriteriaDummyData = [
  {
    id: 1,
    name: "Assigned To Subject",
  },
  {
    id: 2,
    name: "Available At Depot",
  },
  {
    id: 3,
    name: "Available At Site",
  },
  {
    id: 4,
    name: "Damaged",
  },
  {
    id: 5,
    name: "Destroyed At Site",
  },
  {
    id: 6,
    name: "Expired",
  },
  {
    id: 7,
    name: "In Transit",
  },
  {
    id: 8,
    name: "Lost By Subject",
  },
  {
    id: 9,
    name: "Missing",
  },
  {
    id: 10,
    name: "Missing At Site",
  },
];
